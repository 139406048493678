<script>
  import { setContext } from 'svelte'
  import { store, action } from './store.js'
  import navHelper from './lib/nav-helper.js'
  import Start from './pages/Start.svelte'
  
  setContext('store', store)
  setContext('action', action)

  $: page = $store.route 

</script>
<div on:click={navHelper(action('doUpdateUrl'))}>
  <svelte:component this={page} />
</div>
<style>
  :global(body) {
    height: 100vh;
  }
  div {
    height: 100%;
  }
</style>
