<script>
    import Logo from '../components/Logo.svelte'
    import { getContext, onMount } from 'svelte'
    import i18n from '../lib/i18n.json'
    let lang = 'en'
    let citizen
    let thankYou = t('thank-you')
    let thanksTitle = t('thanks-title')
    let thanksHeader = t('thanks-header')

    const store = getContext('store')
    const action = getContext('action')

    $: citizen = $store.citizen

    $: {
        if (citizen.language === 'spanish') {
            lang = 'es'
        } else {
            lang = 'en'
        }
        thankYou = t('thank-you')
        thanksTitle = t('thanks-title')
        thanksHeader = t('thanks-header')
    }

    function t(key) {
        return i18n[lang][key]
    }

    function close() {
        if (window) {
            window.close()
            //action('doUpdateUrl')('/preferences')
        }
    }
</script>

<style>
    section {
        height: 100%;
        display: grid;
        grid-template-areas:
            'header'
            'main'
            'footer';

        grid-template-rows: 72px auto 48px;
    }

    header {
        grid-area: header;
    }

    header > div {
        height: 100%;
    }
    .title {
        color: white;
    }

    .center {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    main {
        grid-area: main;
        display: flex;
        flex-direction: column;
    }

    .box {
        width: 350px;
    }

    footer {
        grid-area: footer;
    }
</style>

<section>
    <header class="navbar is-primary center">
        <div class="navbar-brand center">
            <h1 class="title">{thanksHeader}</h1>
        </div>
    </header>
    <main class="center">
        <Logo />
        <div class="content">
            <h3 class="center">{thanksTitle}</h3>

            <div class="box">
                <p>{thankYou}</p>
                <button class="button center" on:click={close}>Close</button>
            </div>
        </div>
    </main>
    <footer class="center">
        <p>All Rights Reserved. &copy; Citibot, LLC 2020</p>
    </footer>
</section>
