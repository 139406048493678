const [
    GET_CITIZEN_STARTED,
    GET_CITIZEN_SUCCESSFUL,
    GET_CITIZEN_ERROR,
    PUT_CITIZEN_STARTED,
    PUT_CITIZEN_ERROR,
    PUT_CITIZEN_SUCCESS,
] = [
    'GET_CITIZEN_STARTED',
    'GET_CITIZEN_SUCCESSFUL',
    'GET_CITIZEN_ERROR',
    'PUT_CITIZEN_STARTED',
    'PUT_CITIZEN_ERROR',
    'PUT_CITIZEN_SUCCESS',
]

const initialState = {
    loading: false,
    data: {
        _id: '',
        categories: [],
        language: 'English',
    },
}

export default {
    name: 'citizen',
    doPutCitizen(doc) {
        return async ({ dispatch, store, apiUrl }) => {
            dispatch({ type: PUT_CITIZEN_STARTED })
            const accessToken = store.selectAccessToken()
            const url = apiUrl()
            // Account is a phoneNumber of the Citibot account
            const account = store.selectAccountNo()
            const result = await fetch(url + `/citizens/me`, {
                method: 'PUT',
                body: JSON.stringify({ account, citizen: doc }),
                headers: {
                    'content-type': 'application/json',
                    authorization: `Bearer ${accessToken}`,
                },
            }).then(async res => {
                const body = await res.json()
                return body
            })

            if (result) {
                dispatch({ type: 'PUT_CITIZEN_SUCCESS', payload: result })
            } else {
                dispatch({
                    type: 'PUT_CITIZEN_ERROR',
                    payload: 'could not save preferences',
                })
            }

            return Promise.resolve({ ok: true })
        }
    },
    doGetCitizen() {
        return ({ dispatch, store, apiUrl }) => {
            dispatch({ type: GET_CITIZEN_STARTED })
            const accessToken = store.selectAccessToken()
            // Account is a phoneNumber of the Citibot account
            const account = store.selectAccountNo()
            const url = apiUrl()

            if (!url) {
                dispatch({
                    type: GET_CITIZEN_ERROR,
                    payload: new Error('apiUrl is not set'),
                })
                return Promise.resolve(false)
            }
            if (!accessToken) {
                dispatch({
                    type: GET_CITIZEN_ERROR,
                    payload: new Error('access token required!'),
                })
                return Promise.resolve(false)
            }

            // Fetch citizen and account information
            return fetch(url + `/citizens/me?account=${account}`, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    authorization: `Bearer ${accessToken}`,
                }
            })
                .then(res => res.json())
                .then(({ citizen }) => {
                    dispatch({ type: GET_CITIZEN_SUCCESSFUL, payload: citizen })
                    return true
                })
                .catch(err => {
                    dispatch({ type: GET_CITIZEN_ERROR, payload: err })
                    return false
                })
        }
    },
    selectCitizenLoading(state) {
        return state.citizen.loading
    },
    selectCitizen(state) {
        return state.citizen.data
    },
    reducer(state = initialState, { type, payload }) {
        if (equals(type, GET_CITIZEN_STARTED)) {
            return merge(state, { loading: true })
        }
        if (equals(type, GET_CITIZEN_SUCCESSFUL)) {
            return merge(state, { loading: false, data: payload })
        }
        if (equals(type, GET_CITIZEN_ERROR)) {
            return merge(state, { loading: false, data: null, error: payload })
        }
        if (equals(type, PUT_CITIZEN_STARTED)) {
            return merge(state, { saving: true })
        }
        if (equals(type, PUT_CITIZEN_SUCCESS)) {
            return merge(state, { saving: false, data: payload })
        }
        if (equals(type, PUT_CITIZEN_ERROR)) {
            return merge(state, { saving: false, error: payload })
        }
        return state
    },
}

function merge(a, b) {
    return Object.assign({}, a, b)
}

function equals(a, b) {
    return a === b
}
