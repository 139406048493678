<script>
    import Logo from '../components/Logo.svelte'
    import { getContext, onMount } from 'svelte'

    const store = getContext('store')
    const action = getContext('action')

    const [READY, ERROR] = ['READY', 'ERROR']

    let account = null
    let citizen = null

    let state = READY

    onMount(async () => {
        account = $store.queryObject.account || ''
        citizen = $store.queryObject.citizen || ''

        if (or(not(length(account, 11)), not(length(citizen, 11)))) {
            state = ERROR
            return
        }

        const res = await action('doSetAccountAndCitizen')(account, citizen)
        //const account = await action('doGetAccount')(account)

        //if (res) { action('doUpdateQuery')('?') }
        // TODO: Handle false condition
    })

    // pure functions
    function or(a, b) {
        return a || b
    }
    function not(a) {
        return !a
    }
    function length(a, n) {
        return a.length === n
    }
</script>

<style>
    section {
        height: 100%;
    }
    .center {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .button {
        margin-top: 16px;
    }

    p {
        font-size: 0.6em;
        font-weight: 300;
    }
</style>

<section class="section center">
    <Logo />
    <h1 class="title">Citibot Notify</h1>
    <h2 class="subtitle">real-time city notifications</h2>
    {#if state === ERROR}
        <div class="notification is-danger">
            <h1 class="title">Error Occured!</h1>
            <p>
                An error has occured, access to this app should be from a
                citibot message, it appears access to this application has
                occured without the necessary identifiers.
            </p>
        </div>
    {/if}
    {#if state === READY}
        <div class="box content">
            <h3 class="center">
                Welcome to Citibot Notify
                <h3>
                    <hr />
                    <p>
                        Citibot Notify provides you with real-time updates from
                        your city support team.
                    </p>
                    <p>
                        Please register/sigin to setup your notification
                        preferences!
                    </p>
                    <div class="center is-full-width">
                        <!-- skipping login for now
      <button 
        class="button is-primary" 
        on:click={action('doLogin')}>
        Register/SignIn
      </button>
      -->
                        <a class="button is-primary" href="/login">
                            Register/SignIn
                        </a>
                    </div>
                </h3>
            </h3>
        </div>
    {/if}
</section>
