// Accounts Bundle
//
//
const [GET_ACCOUNT_STARTED, GET_ACCOUNT_SUCCESSFUL, GET_ACCOUNT_ERROR] = [
    'GET_ACCOUNT_STARTED',
    'GET_ACCOUNT_SUCCESSFUL',
    'GET_ACCOUNT_ERROR',
]
const initialState = { loading: false, data: null, error: null }
import { pathOr } from 'ramda'

export default {
    name: 'account',
    // action creators
    doGetAccount() {
        return async function({ dispatch, store, apiUrl }) {
            dispatch({ type: GET_ACCOUNT_STARTED })
            const accessToken = store.selectAccessToken()
            const url = apiUrl()
            // Account is a phoneNumber of the Citibot account
            const account = store.selectAccountNo()
            if (!url) {
                dispatch({
                    type: GET_ACCOUNT_ERROR,
                    payload: new Error('ApiURL is not set'),
                })
                return Promise.resolve(false)
            }
            // if no token, can't get account
            if (!accessToken) {
                dispatch({
                    type: GET_ACCOUNT_ERROR,
                    payload: new Error('No Access Token'),
                })
                return Promise.resolve(true)
            }

            return fetch(url + `/citizens/me?account=${account}`, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    authorization: `Bearer ${accessToken}`,
                }
            })
                .then(res => res.json())
                .then(({ account }) => {
                    dispatch({ type: GET_ACCOUNT_SUCCESSFUL, payload: account })
                    return true
                })
                .catch(err => {
                    dispatch({ type: GET_ACCOUNT_ERROR, payload: err })
                    return false
                })
        }
    },
    // selectors
    //
    selectAccount(state) {
        return state.account.data
    },
    selectLanguages(state) {
        const languages = pathOr(
            ["English", "Spanish"],
            ['account', 'data', 'languages'],
            state
        )
        return languages
    },
    selectCategories(state) {
        const categories = pathOr(
            { English: [], Spanish: [] },
            ['account', 'data', 'notifyData', 'categories'],
            state
        )
        return categories
    },
    // reducer
    reducer: function(state = initialState, { type, payload }) {
        if (equals(type, GET_ACCOUNT_STARTED)) {
            return merge(state, { loading: true, data: null })
        }
        if (equals(type, GET_ACCOUNT_SUCCESSFUL)) {
            return merge(state, { loading: false, data: payload })
        }
        if (equals(type, GET_ACCOUNT_ERROR)) {
            return merge(state, { loading: false, data: null, error: payload })
        }
        return state
    },
    // persistActions: [GET_ACCOUNT_SUCCESSFUL],
}

// pure functions
function merge(a, b) {
    return Object.assign({}, a, b)
}

function equals(a, b) {
    return a === b
}
